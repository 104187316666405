import _ from 'lodash'
import axios from '../../../common/axios.js'
import {gmapApi} from 'vue2-google-maps'
import * as turf from '@turf/turf'

export default {
	date(){
		return {
			google_maps_cancelSearch: null
		}
	},
	mounted(){
		this.$gmapApiPromiseLazy().then(() => {
			if(this.google) {
				this.geocoder = new this.google.maps.Geocoder();
			}
		});
	},
	created(){
		
	},
	methods: {
		getSearch_google_maps(){
			let self = this

			if (this.google_maps_cancelSearch) this.google_maps_cancelSearch.cancel('Start new search, stop active search');
			this.google_maps_cancelSearch = axios.CancelToken.source();

			let options = []
			let limit = 5

			// console.log('getSearch_google_maps')

			//AS--> On met à jour le state pour indiquer que la recherche est en cours
			this.updateState('google_maps', 'loading')

			//AS--> Limite les recherches à la zone de sourcing
			options.push(this.getLocationrestriction())

			//AS--> Limite les recherches aux types de résultats autorisés
			options.push(this.getTypesRestrictions())

			//AS--> On lance la recherche
			// this.geocoder.geocode({address: this.search_term, region: 'FR'}, (results, status) => {
			// 	console.log('results', results)
			// 	
			axios.get('/plugin_projet/plugin_projet_iannuaire/places_autocompletion/'+encodeURI(this.search_term.replaceAll(' ', '+'))+'/1/'+window.btoa(encodeURI(options.join('&'))), { cancelToken: this.google_maps_cancelSearch.token })
		      .then(function (response) {

		      	let results = response.data
		      	console.log('results', results)

				if(results && results.length){

					let search_results = {
						"cities" : [],
						"addresses" : []
					}

					let conversion = {
						// "locality"    : "cities",
						"locality"       : "addresses", //AS--> Finalement, on traite les villes comme des adresses
						"route"          : "addresses",
						"street_address" : "addresses",
						"premise"        : "addresses",
					}

					results.forEach((result) => {

		      			//AS--> Pour chaque type de résultat reçu, on vérifie dans quelle catégorie il doit apparaître, et qu'on n'a pas dépassé le nombre limite on le traite
		      			result.types.forEach((type) => {
		      				if(_.keys(conversion).includes(type) && search_results[conversion[type]].length < limit){
								let result_for_motor = {
									"properties" : result
								}
								result_for_motor.properties.name = result.nom
								search_results[conversion[type]].push(result_for_motor)
		      				}
		      			})

					})

					//AS--> On ajoute les résultats à la liste
					self.addResults(_.cloneDeep(search_results))

				}

				//AS--> On met à jour le state pour indiquer que la recherche est terminée
				self.updateState('google_maps', 'loaded')

			})
		},

		getLocationrestriction(){

			//AS--> Récupère les coordonnées du rectangle entourant la zone grace à Turf.js
			let bbox = turf.bbox(this.$store.state.ui.sourcing.zone)
			console.log('bbox', bbox)
			return 'locationrestriction=rectangle:'+bbox[1]+','+bbox[0]+'|'+bbox[3]+','+bbox[2]
		},

		getTypesRestrictions(){
			return 'types='+['locality','route','street_address','premise'].join('|')
		}

	},
	computed:{
		google: gmapApi,
	},
	components:{
		
	}
}